import React, { useEffect } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import stateAction from "../store/actions/state.action"; 
import '../sass/utilities.scss';
import '../sass/components/button.scss';
import '../sass/components/text-input.scss';
import first from '../assets/images/Backho.me_008-min.jpg';
import second from '../assets/images/Backho.me_011-min.jpg';
import third from '../assets/images/4n-min.jpg';
 
export default function Dashboard(props) { 
    const userDoc = useSelector((state) => state.user.doc);
    const dispatch = useDispatch(); 
 
    useEffect(() => {
        document.body.style.backgroundColor = '#F7F7F7';
        dispatch(stateAction.updateState({currentFormTitle: "How It Works"})); 
    }, []);
 
    return (
    <div className='container' style={{minHeight: '75vh'}}>
        <h1>How Backho.me works</h1>

        <div className='card relative card-how'>
            <div className="how-cols">
                <div className="how-left">
                    <h3>Enter the unique code into the website</h3>
                    <p>Purchase your backho.me Tag and activate it by scanning it with an NFC device or use the unique code on the website.</p>
                </div>
                <div className="how-it-works-img">
                    <img src={first} />
                </div>
            </div>
        </div>

        <div className='card relative card-how'>
            <div className="how-cols">
                <div className="how-left">
                    <h3>Stick & Leave It</h3>
                    <p>Once your tag is activated, attach it to your preffered device then forget about it.</p>
                </div>
                <div className="how-it-works-img">
                    <img src={second} />
                </div>
            </div>
        </div>
        <div className='card relative card-how'>
            <div className="how-cols">
                <div className="how-left">
                    <h3>Lost & Found</h3>
                    <p>When you have lost your device, log onto the website and mark it as lost. When the device is found the tag will be scanned and you will be notified that its been found.</p>
                </div>
                <div className="how-it-works-img">
                    <img src={third} />
                </div>
            </div>
        </div>
        <div  className="flex">
        <Link to="/login" className='big-button-white'>Get started</Link>
        </div>
    </div>
    );

}
