import React, { useEffect } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import stateAction from "../store/actions/state.action"; 
import userAction from "../store/actions/user.action";
import '../sass/utilities.scss';
import '../sass/components/button.scss';
import '../sass/components/text-input.scss';
import '../sass/components/dashboard.scss';
import '../sass/components/card.scss';
import { ITEM_SERVICE } from "../services/item.service";
import {ReactComponent as CircleBg} from '../assets/images/circular-bg.svg'; 

export default function Dashboard(props) { 
    const userDoc = useSelector((state) => state.user.doc);
    const uid = useSelector((state) => state.user.config?.uid); // Use optional chaining to prevent errors
    const redirect = useSelector((state) => state.state.routeFromLogin);
    const [items, setItems] = React.useState([]);
    const dispatch = useDispatch(); 
    const navigate = useNavigate(); 

    useEffect(() => {
        document.body.style.backgroundColor = '#E9F5F1';
        dispatch(stateAction.updateState({currentFormTitle: "Dashboard"})); 
        ITEM_SERVICE.getItemsByUserId(uid, onGetItems);
        if(redirect){
            navigate(redirect);
        } 
    }, []);
 
    const onGetItems = (response) => {
        if(response && response.length > 0){
            var items = [];
            for(var i=0; i< response.length; i++){
                items.push(response[i].data());
            }
            setItems(items); 
        }
    }
    function toActivateTag(){
        navigate("/activeTag");
    }

    function toAccountSettings(){
        navigate("/account");
    } 
    

    const navigateToEditTag = (e, item) => {
        e.preventDefault();  
        dispatch(userAction.updateUser({tagToUpdate : item}));
        navigate("/editTag");
    }
  
    return (
    <div className='container' style={{minHeight: '75vh'}}>
        <h2 style={{textAlign:'center'}}>Hi {userDoc ? userDoc.fullName : ""} </h2>
        <p style={{textAlign:'center', paddingLeft:'2rem', paddingRight:'2rem'}}>
            Here you can manage your items, activate new tags, 
            change their status and update your contact details.
        </p>
        <div style={{marginBottom:'3rem', display:'flex', justifyContent:'center'}}> 
            <button className="button" style={{marginRight:'5%'}} onClick={(e) => toActivateTag()}> 
                Activate a Tag
            </button>
            <button className="button" onClick={(e) => toAccountSettings()}> Account Settings</button>
      </div>
        {
        items.map( (item, i) => {
            return (
                <div key={i} type="button" class="card pointer" onClick={ (e) => { navigateToEditTag(e, item)}}>
                    <svg style={{position:'absolute', top:'1rem', right:'2rem',
                            width: '165px', height :'165px' }}> 
                        <mask id="circle-mask"> 
                            <CircleBg height='165' width='165' />
                        </mask> 
                        <rect height='100%' width='100%' fill="rgb(233, 245, 241)" mask="url(#circle-mask)"/>
                    </svg> 
                    <div className="card-content-container">
                        <h4 class="title">NFC TAG -</h4>
                        <h3 className="item-title">{item.tag}</h3>
                        <p className="item-name">{item.name}</p>
                    </div>
                    <button type="button" class="button card-button" onClick={(e) => navigate("/found/reunite")} style={ item.status == "" ? 
                        {backgroundColor : 'black', color: 'white'}  : 
                        {backgroundColor : 'white', color: 'black', border: '1px solid black'} }>
                        {item.status === "LOST" ? "LOST" : "FOUND"}
                    </button>
                </div>
            )
        })
       }
       <div className="flex">
        <a className="big-button-white" style={{
            paddingTop:'50px',
            paddingBottom: '50px',
            borderRadius: '12px'
        }}
        onClick={(e) => toActivateTag()}> 
        ACTIVATE A NEW TAG
        </a> 
        </div>
     </div>
    );

}
