import React, { useEffect, useState } from "react";
import { getAuth, signOut } from 'firebase/auth';

function LogoutButton() {
  const [user, setUser] = useState(null); // State to track user authentication

  useEffect(() => {
    const auth = getAuth();

    // Listen for changes in the user's authentication state
    auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
  }, []);

  const handleLogout = () => {
    const auth = getAuth();

    signOut(auth)
      .then(() => {
        console.log('User signed out successfully');
        // Refresh the page
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  // Render the logout button only if there's a user logged in
  return user ? (
    <a onClick={handleLogout} href="#">
      Logout
    </a>
  ) : null;
}

export default LogoutButton;
