import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "@firebase/firestore"; 
import { getAuth, setPersistence } from "firebase/auth"; // Import additional Firebase Authentication functions
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyBZRvXDmXCKU42IzaFbNNw-GsvGbZVwjOo",
    authDomain: "nfc-tags-933cf.firebaseapp.com",
    projectId: "nfc-tags-933cf",
    storageBucket: "nfc-tags-933cf.appspot.com",
    messagingSenderId: "635010642278",
    appId: "1:635010642278:web:76584c0933b47a8087d8a7",
    measurementId: "G-H33LY1B23H"
  };


const app = initializeApp(firebaseConfig); 
export const auth = getAuth(app);

setPersistence(auth)
  .then(() => {
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app); 
