import React, { useEffect } from "react";
import { ITEM_SERVICE } from "../services/item.service";
import { useDispatch, useSelector } from "react-redux"; 
import '../sass/utilities.scss';
import '../sass/components/button.scss';
import '../sass/components/text-input.scss';
import userAction from "../store/actions/user.action";
import { useNavigate } from "react-router-dom";

export default function EditTag(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const tagToUpdate = useSelector((state) => state.user.tagToUpdate);
    const [tagToEdit, setTagToEdit] = React.useState(tagToUpdate);
    const [errorMessage, setErrorMessage] = React.useState(''); 

    useEffect(() => {
         if(!tagToUpdate  || !tagToUpdate.name ){
            navigate("/dashboard");
        } 
    }, []);
 
    const onUpdateTag = (response) => {
       if(response && response.data()){
            const tagId = response.id;  
            const data = response.data();
           navigate("/dashboard"); 
           dispatch(userAction.updateUser({tagToUpdate : null}));
       }
    }

    function validate(){
        var valid = true; 
        if(!tagToEdit){
            setErrorMessage("Something went wrong")
            valid = false;
        }
        if(!tagToEdit.name || tagToEdit.name.length == 0){
            setErrorMessage("You must enter a tag name")
            valid = false;
        }
        return valid; 
    }

    const onGetItemByTagId = (response) => {
        if(response && response.docs){
            var itemDocId = response.docs[0].id;
            var doc = JSON.parse(JSON.stringify(tagToEdit));
            doc.id = itemDocId;
            ITEM_SERVICE.updateItem(doc, onUpdateItem);
        }
    }
    
    const onUpdateItem = (response) => {
        navigate("/dashboard");
    }

    const submitForm = (e) => {
        e.preventDefault();
       
        if(validate() && tagToEdit && tagToEdit.name){
            ITEM_SERVICE.getItemByTagId(tagToEdit.tagId, onGetItemByTagId);
            setErrorMessage("");
        }else {
            setErrorMessage("Your tag was not updated");
        }
    }
 

    return (
    <div className='container' style={{minHeight: '75vh'}}>
        <h2> Edit your tag </h2>
        <p> Change the name and status of your tag </p>
         <form style={{marginTop: '2em'}} onSubmit={(e) => submitForm(e)}>

        <p> {tagToEdit?.name} </p>
        <p> {tagToEdit?.tag} </p>
        <div className="input-container">
            <input placeholder="TAG ID" value={tagToEdit?.name} onChange={(e) => 
                {tagToEdit.name = e.target.value;
                    setTagToEdit(prevState => ({
                        ...prevState,
                        name: e.target.value
                      }))
                }}
                type="text" />
        </div>
        <div className="input-container">
            <input placeholder="Tag Id" value={tagToEdit?.tag} type="text" disabled={true} />
        </div> 

        <h2> Status </h2>
        <button type="button" class="button edit-tag-btn" onClick={(e) =>  
            {tagToEdit.status = 'LOST';
                setTagToEdit(prevState => ({
                    ...prevState,
                    status: 'LOST'
                }))
            }}
            style={{backgroundColor : tagToEdit?.status === 'LOST' ? 'black' : 'white', 
            color: tagToEdit?.status === 'LOST' ? 'white' : 'black' }}>
            LOST 
        </button>
        <button type="button" class="button edit-tag-btn" onClick={(e) =>   
            {   
                tagToEdit.status = 'FOUND';
                setTagToEdit(prevState => ({
                    ...prevState,
                    status: 'FOUND'
                }))
            }}
            style={{backgroundColor : tagToEdit?.status === 'FOUND' ? 'black' : 'white', 
            color: tagToEdit?.status === 'FOUND' ? 'white' : 'black' }}>
            FOUND
        </button> 

        <h2> Updates </h2> 

        <p>Please check your emails to see if your tag has been scanned
             and your item has been found</p>

             
        <p style={{color:'red'}}> {errorMessage} </p> 
        <button className="button" style={{ marginTop: '2rem', width:'100%' }}
        onClick={(e) => submitForm(e)}> Save Edits </button>
        
        </form>
      </div> 
    );

}
