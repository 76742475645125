import React, { useEffect } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import stateAction from "../store/actions/state.action"; 
import circularBgGreen from '../assets/images/circular-bg-green.svg';
import '../sass/utilities.scss';
import '../sass/components/button.scss';
import '../sass/components/text-input.scss';
import '../sass/index.scss';
 
export default function HowToScan(props) { 
    const userDoc = useSelector((state) => state.user.doc);
    const dispatch = useDispatch(); 
 
    useEffect(() => {
        document.body.style.backgroundColor = '#F7F7F7';
        dispatch(stateAction.updateState({currentFormTitle: "How to scan"})); 
    }, []);
 
    return (
    <div className='container'>
            <div className="text-center max-w-100 mx-auto">
                 <iframe width="560" height="315" src="https://www.youtube.com/embed/SUk2mgQJKfg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className="how-to-container">
                <h3>Below are the 10 steps for using and turning on NFC on a smartphone to scan NFC tags (for non-payment purposes),
including both iPhone and Android devices:</h3>
                <p>**For iPhone:** (Alternatively, You can also download NFC reading apps to skip the below on Iphone 7 up)</p>
                <ol>
                    <li>Check Compatibility and iOS Version: Ensure your iPhone is compatible with NFC (iPhone 7 and newer) and running iOS 11 or later.</li>
                    <li>Enable NFC on iPhone Xs/Xr/11/13/14: Navigate to "Settings" - "Control Center" and add the NFC Tag Reader to the Control Center if it isn’t already there.</li>
                    <li>Scan NFC Tags on iPhone Xs/Xr/11/12/13/14: From the Control Center, tap the NFC Tag Reader icon, then hold the top edge of your iPhone near the NFC tag to scan it.</li>
                    <li>Enable NFC on iPhone 7/8/X (with iOS 14 or later): Swipe up from the Home screen to open Control Center and tap the NFC Tag Reader icon to enable it.</li>
                    <li>Scan NFC Tags on iPhone 7/8/X (with iOS 14 or later): Hold your iPhone near the NFC tag to scan it. A notification will appear for you to tap and view the NFC content.</li>
                    <li>For Android - Check NFC Support: Confirm that your Android device supports NFC (most devices past 2010 with Android 2.3 or later).</li>
                    <li>Turn on NFC: Go to "Settings" - "Connections" (or "Connected devices" on some phones) - "NFC" and toggle the switch to enable NFC. The location of the NFC settings may vary based on the manufacturer and Android version.</li>
                    <li>Enable Android Beam (if available): Now that NFC is enabled, the Android Beam function should automatically turn on. If it doesn’t, tap on "Android Beam" and select "Yes" to turn it on.</li>
                    <li>Scan NFC Tags: Hold the back of your Android device near the NFC tag that you wish to scan. The NFC antenna location can vary, but it's typically near the camera or the top of the device.</li>
                    <li>Handling Scanned Data: If the tag contains a URL, you may be prompted to select which browser you’d like to use to open the link. You can choose "Just once" or "Always" depending on your preference. If it's an action or text, the corresponding application on your phone will
handle it.</li>
                </ol>
                <p>Remember that NFC must be turned on and the device's screen must be unlocked for NFC scanning to work. Also, proximity
is key, as NFC only works over a few centimeters' distance.</p>
            </div>
        <div>
        </div>
        <div  className="flex">
        <Link to="/tag" className='big-button-white'>Scan A Tag</Link>
        </div>
    </div>
    );

}
