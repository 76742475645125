import React, { useEffect } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import stateAction from "../store/actions/state.action"; 
import circularBgGreen from '../assets/images/circular-bg-green.svg';
import nfcImg from '../assets/images/NFC_svg.svg';
import '../sass/utilities.scss';
import '../sass/components/button.scss';
import '../sass/components/text-input.scss';
import '../sass/index.scss';
 
export default function Found(props) { 
    const userDoc = useSelector((state) => state.user.doc);
    const dispatch = useDispatch(); 
 
    useEffect(() => {
        document.body.style.backgroundColor = '#F7F7F7';
        dispatch(stateAction.updateState({currentFormTitle: "Found"})); 
    }, []);
 
    return (
    <div className='container' style={{minHeight: '75vh'}}>
        <div className="page-header relative">
            <h3>Scanning a FOUND tag</h3> <img className="absolute found-image-header" src={nfcImg} />
        </div>
        <div className='card relative card-how card-bg text-center mt-0'
        style={{
            backgroundImage: `url(${circularBgGreen})`
        }}
        >
            <div className="relative">
                <h3>HOLD YOUR DEVICE<br></br> OVER THE TAG</h3>
            </div>
               
        </div>
        <div>
            <h3 className="text-center">How do I scan a NFC?</h3>
            <p className="text-center">Not sure on how to scan using <strong>NFC technology?</strong>
            <br></br>
            Take a look at our guides.</p>
        </div>
        <div  className="flex">
        <Link to="/how-to-scan" className='big-button-white'>How To Scan</Link>
        </div>
    </div>
    );

}
