import React, { useEffect } from "react";
import { USER_SERVICE } from "../services/user.service";
import { useDispatch, useSelector } from "react-redux";
import '../sass/utilities.scss';
import '../sass/components/button.scss';
import '../sass/components/text-input.scss';
import stateAction from "../store/actions/state.action";
import userAction from "../store/actions/user.action";
import '../sass/components/dashboard.scss';
import { useNavigate } from 'react-router-dom';

export default function Account(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const userDoc = useSelector((state) => state.user.doc);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [country, setCountry] = React.useState(userDoc?.country);
    const [address, setAddress] = React.useState(userDoc?.address);
    const [dob, setDOB] = React.useState(userDoc?.dob);
    const [fullName, setFullName] = React.useState(userDoc?.fullName);
    const [county, setCounty] = React.useState(userDoc?.county);
    const [mobileNumber, setMobileNumber] = React.useState(userDoc?.mobileNumber);

    useEffect(() => {
        document.body.style.backgroundColor = '#F7F7F7';
        dispatch(stateAction.updateState({currentFormTitle: "Account Settings"})); 
 
        if(!userDoc || !userDoc.email ){
          navigate("/dashboard");
        }   
    }, []);

    const validateMobile = (inputNumber) => {
        let isValid = false;
        //uk only number regex
        isValid = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(inputNumber);
        if(inputNumber == '' || inputNumber == undefined || inputNumber == null){
          isValid = false;
        }
        return isValid; 
      }

    const submitForm = (e, fullName, dob, address, county, country, mobile) => {
        e.preventDefault();
        if(fullName && dob && address && county && country && validateMobile(mobile)){
            setErrorMessage("");
            var tempUserDoc = JSON.parse(JSON.stringify(userDoc));
            tempUserDoc.fullName = fullName;
            tempUserDoc.dob = dob; 
            tempUserDoc.address = address;
            tempUserDoc.country = country;
            tempUserDoc.county = country; 
            tempUserDoc.mobileNumber = mobile; 
            console.log("update userdoc passval", tempUserDoc); 
            USER_SERVICE.updateUserDoc(tempUserDoc, onUpdateUser); 
        } else {
            setErrorMessage("Please fill in all fields");
        }
    }

    const onUpdateUser = (response, currDoc) => {
         dispatch(userAction.updateUser( { doc : currDoc }));  
         navigate("/dashboard");
     }

    return (
    <div className='container' style={{minHeight: '75vh'}}>
        <h2> Keep your contact details up to date. </h2>
        <form style={{marginTop: '2em'}} onSubmit={(e) => submitForm(e, fullName, dob, address, county, country, mobileNumber)}>
        <div className="input-container">
            <input placeholder="FULL NAME" value={fullName} onChange={(e) => setFullName(e.target.value)} type="text" id="email" />
        </div>
        <div className="input-container dob">
            <label className="dob_label">D.O.B: </label>
            <input value={dob} onChange={(e) => setDOB(e.target.value)} type="date" id="dob" />
        </div> 
        <div className="input-container">
            <textarea placeholder="ADDRESS" style={{textAlign: '-webkit-auto'}} value={address} onChange={(e) => setAddress(e.target.value)} type="textarea" id="address" />
        </div> 
        <div className="input-container">
            <input placeholder="COUNTY" value={county} onChange={(e) => setCounty(e.target.value)} type="text" id="county" />
        </div> 
        <div className="input-container">
            <input placeholder="COUNTRY" value={country} onChange={(e) => setCountry(e.target.value)} type="text" id="country" />
        </div> 
        <div className="input-container">
            <input placeholder="MOBILE NUMBER" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} type="number" id="mobileNumber" />
        </div> 
        <p style={{color:'red'}}> {errorMessage} </p>

        <button className="button" style={{ marginTop: '2rem', width:'100%' }} onClick={(e) => submitForm(e, fullName, dob, address, county, country, mobileNumber)}> Save Settings </button>
        </form>
    </div>
    );
}
