import React, { useEffect } from "react";
import { TAG_SERVICE } from "../services/tag.service";
import { ITEM_SERVICE } from "../services/item.service";
import { useDispatch, useSelector } from "react-redux"; 
import '../sass/utilities.scss';
import '../sass/components/button.scss';
import '../sass/components/text-input.scss';
import stateAction from "../store/actions/state.action";
import { useNavigate } from "react-router-dom";

export default function ActivateTag(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const unregisteredTag = useSelector((state) => state.state.unregisteredTag);
    const userDoc = useSelector((state) => state.user);
    const [errorMessage, setErrorMessage] = React.useState(''); 
    const [tagId, setTagId] = React.useState(""); 
    const [activateCode, setActivationCode] = React.useState(""); 
    const [itemName, setItemName] = React.useState(""); 

    useEffect(() => {
        document.body.style.backgroundColor = '#F7F7F7';
        dispatch(stateAction.updateState({currentFormTitle: "Activate Tag"})); 
        if(unregisteredTag) { 
            setTagId(unregisteredTag);
            dispatch(stateAction.updateState({unregisteredTag: null, routeFromLogin: null})); 
         }
    }, []);

    function toScanYourTag(props){
        navigate("/tag");
    }

    const onGetTagByTag = (response) => {
       if(response && response.data()){
            const tagId = response.id;  
            const data = response.data();
            if(data.activationCode && data.activationCode == activateCode){
                var tagDoc = JSON.parse(JSON.stringify(data));
                tagDoc.id = tagId; 
                tagDoc.status = "activated";
                TAG_SERVICE.updateTagDoc(tagDoc, onUpdateTag);                 
                ITEM_SERVICE.createItem({
                    createdDate : new Date(),
                    foundBy: "", 
                    name: itemName,
                    status:  "",
                    tag: tagDoc.tag,
                    tagId: tagId, 
                    userId: userDoc.config.uid
                }, onUpdateTag);
            } else {
                setErrorMessage("Activation code or Tag ID is incorrect.")
            }
        
       }
    }

    const submitForm = (e, tagId, activateCode, itemName) => {
        e.preventDefault();
        if(tagId && activateCode && itemName){
            TAG_SERVICE.getTagByTag(tagId, onGetTagByTag);
            setErrorMessage("");
        }else {
            setErrorMessage("Please fill in all fields");
        }
    }

    const onUpdateTag = (response) => { 
        navigate("/activeTag/activated"); 
        setErrorMessage("");
        setTagId("");
        setActivationCode("");
        setItemName("");
    }

    return (
    <div className='container' style={{minHeight: '75vh'}}>
        <h2> Scan to get your code </h2>
        <p>If your have your tags mixed up you can scan your tag here to populate your tag ID. Then use your activation code to activate your tag.</p>
        <button className="button" style={{width:"100%"}} onClick={(e) => toScanYourTag()}>  Scan Your Tag </button>
        <h2>Activate Tag</h2>
        <p> Your activation code can be found on the back of your tag packaging. </p>
        <form style={{marginTop: '2em'}} onSubmit={(e) => submitForm(e, tagId, activateCode, itemName)}>
            <div className="input-container">
                <input placeholder="TAG ID" value={tagId} onChange={(e) => setTagId(e.target.value)} type="text" />
            </div>
            <div className="input-container">
                <input placeholder="ACTIVATION CODE" value={activateCode} onChange={(e) => setActivationCode(e.target.value)} type="text" />
            </div>
            <div className="input-container">
                 <input placeholder="NAME YOUR ITEM" value={itemName} onChange={(e) => setItemName(e.target.value)} type="text" />
            </div>   
          <p style={{color:'red'}}> {errorMessage} </p> 
          <button className="button" style={{ marginTop: '2rem', width:'100%' }}
           onClick={(e) => submitForm(e, tagId, activateCode, itemName)}> Activate </button>
          <button onClick={(e) => navigate("/how-to-scan") }className="button" style={{ marginTop: '2rem', width:'100%' }}> 
            I'm stuck, help!
            </button>
        </form>
      </div>
    );

}
