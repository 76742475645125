import React, { useState , useEffect} from 'react';
import { auth } from '../store/firebase.config';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import stateAction from '../store/actions/state.action';


export default function PasswordReset() {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.style.backgroundColor = '#F7F7F7';
    dispatch(stateAction.updateState({ currentFormTitle: "Password Reset" }));
  }, []);

  const handleReset = (e) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccessMessage('Password reset email sent. Please check your email.');
      })
      .catch((error) => {
        setErrorMessage('Password reset failed. Please try again.');
      });
  };

  return (
    <div className='container' style={{ minHeight: '75vh' }}>
      <h4> Password Reset</h4>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start',marginBottom: '25px' }}>
            <Link to={"/login"} style={{ marginRight: '5%' }} className='button'>Login</Link>
            <Link to={"/register"} className='button'>Register</Link>
        </div>
      </div>
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <form onSubmit={handleReset}>
        <div className="input-container">
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='Enter your email'
            type="email"
            id="email"
          />
        </div>
        <button
          className="button"
          style={{ marginTop: '1rem' }}
          type="submit"
        >
          Reset Password
        </button>
      </form>
    </div>
  );
}
