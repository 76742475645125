import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import stateAction from "../store/actions/state.action";
import '../sass/components/accordion.scss';
import chevronRight from '../assets/images/chevron-right-solid.svg';

export default function Faq(props) {
    const dispatch = useDispatch();

    const Accordions1 = [
        {
            title: "What is Backho.me?",
            text: "Backho.me is a lost and found tag and service for helping you get your lost items returned home."
        }
    ];

    const Accordions2 = [
        {
            title: "What materials are the Backho.me stickers made from?",
            text: "Our tags are used a combination of PET, paper and electronic components for the chip and antenna"
        },
        {
            title: "Is the lost and found tag water resistant?",
            text: "The tag is fully water resistant if undamaged however the device attached to it may not be!"
        },
        {
            title: "How long does the battery last in the lost and found tag?",
            text: "Our Backho.me products dont use batteries - so there is no need to change them"
        },
        {
            title: "Can I use it internationally or is it for domestic use only?",
            text: "Anyone around the world can scan your tags and you will be notified - Our services are run online meaning anyone can find your item, scan the tag and get it back to you."
        },
        {
            title: "What happens if my tag is damaged or breaks? Is there a warranty?",
            text: "We recommend testing your tag once a month to ensure it hasn't been damaged beyond use. This can be done by getting someone to scan it with their device. We appreciate that accidents happen and that time destroys all things. This is why we have priced our tags as cheap as we can so it doesn't break the bank to protect your items. At the moment we dont have a replacement option so a new tag will need to be purchased. "
        },
        {
            title: "Can the tag be used for pets or is it only for inanimate items?",
            text: "We wouldn't recommend sticking our sticker tags to your pets, however you can use our other products to do this although there are already RFID products available for pet owners that are used by UK police and vets to return non-human family members."
        },
        {
            title: "Does the tag need to be within a certain range of my phone to work?",
            text: "You can use any device to scan the tag, depending on wich tag you have the range can vary to scanning it however you do not need any device to make the tags work. So feel free to attach them to anything - digital or not."
        },
        {
            title: "When will I receive my order?",
            text: "All our orders are sent tracked via Royal Mail who will provide you with a tracked link."
        }
    ];

    const Accordions3 = [
        {
            title: "How secure is the data transmitted by the tag?",
            text: "There is no personal data transmitted by the tag. We handle all that infomation securly on our servers."
        },
        {
            title: "Is your site secure?",
            text: "We use encryption and the latest technoligy to ensure your data and details are safe"
        },
        {
            title: "How do I change my password?",
            text: "On the login screen, click 'forgot my password' underneath where you would normally enter your password"
        }
    ];

    const Accordions4 = [
        {
            title: "Does the lost and found tag utilize GPS for tracking?",
            text: "Unfortunately to ensure that we keep prices as low as we can for our customers we dont use gps tracking on our tags. There are a lot of providers who do this currently and can work with the Backho.me tags however our goal is getting your items back and bridging that gap between the finder and yourself."
        },
        {
            title: "Is the lost and found tag detectable in metal detectors or x-ray scanners at the airports?",
            text: "Our tags have wires and chips in them and will be detectable by a metal detector. However they are extremely small and shouldn't cause any concern at airport customs, just make sure your device is put through with all your other property."
        },
        {
            title: "Can someone steal my details using my tags",
            text: "No, We only share the data of the finder with you and not any of your details."
        }
    ];

    const Accordions5 = [
        {
            title: "How do I attach the tag to my items?",
            text: "Our Backho.me sticker tags use 3M glue on the underside to attach to a veriety of surfaces. Because our tags can communicate through materials (thin) you can use your own tape or glue to stick them if you want! Our other products will be sold with ways to attach them to your property."
        },
        {
            title: "If my lost item with the tag is found, how will I be notified?",
            text: "When your lost item is found you will get a email notification to the account set up in your profile - There are more contact options coming soon so no matter where you are you will know when its found"
        },
        {
            title: "How many items can one lost and found tag be attached to?",
            text: "Unfortunately it is one tag per item. Unless that item is inside a container, then the options are limitless."
        },
        {
            title: "How often should I replace the tag for optimal performance?",
            text: "We recommend testing the tag monthly, this is simply getting someone to scan the tag for you. (More frequently if its used on a rugged device) however our tags should last the tests of time and we reccomend a new one every 5 years for mobile devices with a case over the tag or 10 years for unmoved unused items."
        },
        {
            title: "Can I track multiple tags with the accompanying app?",
            text: "You can have multiple tags on your account although its only one item per tag."
        },
        {
            title: "Is the app needed for the lost and found tag compatible with all smartphones?",
            text: "Not at all - we use our online services on our website to get handle all the hard work, as long as the device scanning the tag has internet then your property should be back in no time."
        }
    ];

    const Accordions6 = [
        {
            title: "How do i scan the Tags?",
            text: "We've set up a page to help you find out how to scan your tags or tags that you have found, visit the page here"
        }
    ];

    const Accordions7 = [
        {
            title: "Can I return an item? ",
            text: "Sure, get in touch at Contact@backho.me and we can arrange a refund."
        },
        {
            title: "Can i return an item and get a refund?",
            text: "If you have used a tag and want a refund thats fine, we review each case individually, please get in touch at Contact@backho.me"
        },
        {
            title: "what happens to my tag when i get a refund",
            text: "We deactivate all tags when a refund or return is processed so they wont work anymore."
        }
    ];

    const Accordions8 = [
        {
            title: "How do I pay? ",
            text: "You can buy new products on our online store, payments are taken at checkout."
        },
        {
            title: "Where are you located? ",
            text: "Backho.me is based in the UK."
        },
        {
            title: "What if I have another question that isn't listed here?",
            text: "You can contact us via email at Contact@backho.me"
        }
    ];

    const [isOpen1, setIsOpen1] = useState(Array(Accordions1.length).fill(false));
    const [isOpen2, setIsOpen2] = useState(Array(Accordions2.length).fill(false));
    const [isOpen3, setIsOpen3] = useState(Array(Accordions2.length).fill(false));
    const [isOpen4, setIsOpen4] = useState(Array(Accordions2.length).fill(false));
    const [isOpen5, setIsOpen5] = useState(Array(Accordions2.length).fill(false));
    const [isOpen6, setIsOpen6] = useState(Array(Accordions2.length).fill(false));
    const [isOpen7, setIsOpen7] = useState(Array(Accordions2.length).fill(false));
    const [isOpen8, setIsOpen8] = useState(Array(Accordions2.length).fill(false));


    useEffect(() => {
        document.body.style.backgroundColor = '#E9F5F1';
        dispatch(stateAction.updateState({ currentFormTitle: "FAQs" }));
    }, []);

    const toggleAccordion1 = (index) => {
        const updatedState = [...isOpen1];
        updatedState[index] = !updatedState[index];
        setIsOpen1(updatedState);
    };

    const toggleAccordion2 = (index) => {
        const updatedState = [...isOpen2];
        updatedState[index] = !updatedState[index];
        setIsOpen2(updatedState);
    };

    const toggleAccordion3 = (index) => {
        const updatedState = [...isOpen3];
        updatedState[index] = !updatedState[index];
        setIsOpen3(updatedState);
    };

    const toggleAccordion4 = (index) => {
        const updatedState = [...isOpen4];
        updatedState[index] = !updatedState[index];
        setIsOpen4(updatedState);
    };

    const toggleAccordion5 = (index) => {
        const updatedState = [...isOpen5];
        updatedState[index] = !updatedState[index];
        setIsOpen5(updatedState);
    };

    const toggleAccordion6 = (index) => {
        const updatedState = [...isOpen2];
        updatedState[index] = !updatedState[index];
        setIsOpen2(updatedState);
    };

    const toggleAccordion7 = (index) => {
        const updatedState = [...isOpen7];
        updatedState[index] = !updatedState[index];
        setIsOpen7(updatedState);
    };

    const toggleAccordion8 = (index) => {
        const updatedState = [...isOpen8];
        updatedState[index] = !updatedState[index];
        setIsOpen8(updatedState);
    };

    return (
        <div className="container">
            <h3>About us</h3>
            <div className="accordion-container">
                {Accordions1.map((accordion, index) => (
                    <div className={`accordion ${isOpen1[index] ? 'open' : ''}`} onClick={() => toggleAccordion1(index)} key={index}>
                        <div className="accordion-title">
                            <p>{accordion.title}</p>
                        </div>
                        {isOpen1[index] && (
                            <div className="accordion-text">
                                <p>{accordion.text}</p>
                            </div>
                        )}
                        <div>
                            <img className={`accordion-arrow ${isOpen1[index] ? 'rotate' : ''}`} src={chevronRight} alt="Accordion Arrow" />
                        </div>
                    </div>
                ))}
            </div>
            <h3>Questions about the Product?</h3>
            <div className="accordion-container">
                {Accordions2.map((accordion, index) => (
                    <div className={`accordion ${isOpen2[index] ? 'open' : ''}`} onClick={() => toggleAccordion2(index)} key={index}>
                        <div className="accordion-title">
                            <p>{accordion.title}</p>
                        </div>
                        {isOpen2[index] && (
                            <div className="accordion-text">
                                <p>{accordion.text}</p>
                            </div>
                        )}
                        <div>
                            <img className={`accordion-arrow ${isOpen2[index] ? 'rotate' : ''}`} src={chevronRight} alt="Accordion Arrow" />
                        </div>
                    </div>
                ))}
            </div>

            <h3>Questions about my data/account?</h3>
            <div className="accordion-container">
                {Accordions3.map((accordion, index) => (
                    <div className={`accordion ${isOpen3[index] ? 'open' : ''}`} onClick={() => toggleAccordion3(index)} key={index}>
                        <div className="accordion-title">
                            <p>{accordion.title}</p>
                        </div>
                        {isOpen3[index] && (
                            <div className="accordion-text">
                                <p>{accordion.text}</p>
                            </div>
                        )}
                        <div>
                            <img className={`accordion-arrow ${isOpen3[index] ? 'rotate' : ''}`} src={chevronRight} alt="Accordion Arrow" />
                        </div>
                    </div>
                ))}
            </div>

            <h3>Questions about my security?</h3>
            <div className="accordion-container">
                {Accordions4.map((accordion, index) => (
                    <div className={`accordion ${isOpen4[index] ? 'open' : ''}`} onClick={() => toggleAccordion4(index)} key={index}>
                        <div className="accordion-title">
                            <p>{accordion.title}</p>
                        </div>
                        {isOpen4[index] && (
                            <div className="accordion-text">
                                <p>{accordion.text}</p>
                            </div>
                        )}
                        <div>
                            <img className={`accordion-arrow ${isOpen4[index] ? 'rotate' : ''}`} src={chevronRight} alt="Accordion Arrow" />
                        </div>
                    </div>
                ))}
            </div>

            <h3>Question on how to use the product?</h3>
            <div className="accordion-container">
                {Accordions5.map((accordion, index) => (
                    <div className={`accordion ${isOpen5[index] ? 'open' : ''}`} onClick={() => toggleAccordion5(index)} key={index}>
                        <div className="accordion-title">
                            <p>{accordion.title}</p>
                        </div>
                        {isOpen5[index] && (
                            <div className="accordion-text">
                                <p>{accordion.text}</p>
                            </div>
                        )}
                        <div>
                            <img className={`accordion-arrow ${isOpen5[index] ? 'rotate' : ''}`} src={chevronRight} alt="Accordion Arrow" />
                        </div>
                    </div>
                ))}
            </div>

            <h3>Questions on how to scan?</h3>
            <div className="accordion-container">
                {Accordions6.map((accordion, index) => (
                    <div className={`accordion ${isOpen6[index] ? 'open' : ''}`} onClick={() => toggleAccordion6(index)} key={index}>
                        <div className="accordion-title">
                            <p>{accordion.title}</p>
                        </div>
                        {isOpen6[index] && (
                            <div className="accordion-text">
                                <p>{accordion.text}</p>
                            </div>
                        )}
                        <div>
                            <img className={`accordion-arrow ${isOpen6[index] ? 'rotate' : ''}`} src={chevronRight} alt="Accordion Arrow" />
                        </div>
                    </div>
                ))}
            </div>

            <h3>Questions about Refunds?</h3>
            <div className="accordion-container">
                {Accordions7.map((accordion, index) => (
                    <div className={`accordion ${isOpen7[index] ? 'open' : ''}`} onClick={() => toggleAccordion7(index)} key={index}>
                        <div className="accordion-title">
                            <p>{accordion.title}</p>
                        </div>
                        {isOpen7[index] && (
                            <div className="accordion-text">
                                <p>{accordion.text}</p>
                            </div>
                        )}
                        <div>
                            <img className={`accordion-arrow ${isOpen7[index] ? 'rotate' : ''}`} src={chevronRight} alt="Accordion Arrow" />
                        </div>
                    </div>
                ))}
            </div>

            <h3>How to contact us</h3>
            <div className="accordion-container">
                {Accordions8.map((accordion, index) => (
                    <div className={`accordion ${isOpen8[index] ? 'open' : ''}`} onClick={() => toggleAccordion8(index)} key={index}>
                        <div className="accordion-title">
                            <p>{accordion.title}</p>
                        </div>
                        {isOpen8[index] && (
                            <div className="accordion-text">
                                <p>{accordion.text}</p>
                            </div>
                        )}
                        <div>
                            <img className={`accordion-arrow ${isOpen8[index] ? 'rotate' : ''}`} src={chevronRight} alt="Accordion Arrow" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
