import React, { useEffect, useRef } from "react";
import '../sass/utilities.scss';
import '../sass/components/button.scss';
import '../sass/components/text-input.scss';
import '../sass/index.scss';
import '../sass/components/chat.scss'; 
import { useSearchParams } from "react-router-dom"; 
import { useDispatch, useSelector } from "react-redux"; 
import { useNavigate } from "react-router-dom";
import { CHAT_SERVICE } from "../services/chat.service";
import { USER_SERVICE } from "../services/user.service";

export default function Chat() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [chatId, setChatId] = React.useState(null);
    const [currentMessage, setCurrentMessage] = React.useState(null);
    const [currentChatDoc, setCurrentChatDoc] = React.useState(null);
    const [combinedMessages, setCombinedMessages] = React.useState([]);
    const [errorMessage, setErrorMessage] = React.useState('');
    //use redux to figure out if the user is logged in or not, otherwise public link, who is who?
    const userDoc = useSelector((state) => state.user.doc);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sendFormRef = useRef(null); // Create a ref for the send form

 
    useEffect(() => {
        var chatId = searchParams.get("chatId");
        setChatId(chatId);
        if (chatId != null && chatId.length > 0) {
            CHAT_SERVICE.getChatDocByDocId(chatId, onGetChatById);
        }
    }, [searchParams.get("chatId")]); // Adding searchParams.get("chatId") to the dependency array

    useEffect(() => {
        if (sendFormRef.current) {
            sendFormRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [combinedMessages]); // Adding combinedMessages to the dependency array

    const onGetChatById = (response, chatId) => {
        console.log("caht through response", response); 
        if (response) {
            response.id = chatId;
            
            var combinedMessages = [...response.sentMessages, ...response.receivedMessages];

            if(combinedMessages && combinedMessages.length > 1){ 
                combinedMessages.sort((a, b) => {
                    const timestampA = new Date(a.timestamp).getTime();
                    const timestampB = new Date(b.timestamp).getTime();
                    return timestampA - timestampB;
                }); 
            }

            console.log("combined messages", combinedMessages)

            setCombinedMessages(combinedMessages);
            setCurrentChatDoc(response);  
            console.log("chatId userid", response.userId)
            console.log("chatId", response)
            USER_SERVICE.getUserDocById(response.userId, onGetUserDoc)
        }
    }

    const onGetUserDoc = (response) => {
        console.log("chat on get userDoc", response.docs[0].data()); 
    } 

    const doSendMessage = (e) => {
        if(currentMessage && currentMessage.length > 0){
            const messageWithTimestamp = {
                content: currentMessage,
                timestamp: new Date().toISOString(),
                who: userDoc ? "itemOwner" : "itemFinder"
            };            
           
            if(userDoc){
                currentChatDoc.sentMessages.push(messageWithTimestamp); 
            }else {
                currentChatDoc.receivedMessages.push(messageWithTimestamp); 
            } 

            setCombinedMessages(prevMessages => [...prevMessages, messageWithTimestamp]);
            console.log("pushing message", currentChatDoc);
            setCurrentMessage("");
            //need to update the chatdoc from fb before updating here so that we don't overwrite
            CHAT_SERVICE.updateChatDocTranscation(currentChatDoc, onUpdateChatDoc);
        }
    }

    const onUpdateChatDoc = (response) => {
        console.log("on update chatdoc", response); 
    }

    return (
        <div className='chat-container container'>
            { combinedMessages && combinedMessages.length > 0 ?

                combinedMessages.map((message) => (
                    <div
                    key={message.timestamp} className={`message ${message.who === 'itemOwner' ? 'item-owner' : 'item-finder'} `}
                    >
                    <p>{message.content}</p>
                    <span className="time-right">{new Date(message.timestamp).toLocaleString()}</span>
                    </div>
                )) : null}

            <div className="input-container">
                <textarea 
                    ref={sendFormRef}
                    className="send-form" 
                    placeholder="Send message" value={currentMessage} 
                    onChange={(e) => setCurrentMessage(e.target.value)} 
                    type="text" 
                />
                <button className="button" onClick={doSendMessage}>Send</button> 
            </div>

            </div>

       
    );
}
