import { React, useEffect} from 'react'; 
import { Box } from '@mui/system';
import hero_bg from '../assets/images/hero-background.jpg';
import hero_icon from '../assets/images/hero-icon.svg';
import icon1 from '../assets/images/question-mark.svg';
import icon2 from '../assets/images/exclamation-mark.svg';
import icon3 from '../assets/images/shoping-icon.svg' 
import '../sass/components/homepage.scss';
import stateAction from '../store/actions/state.action'; 
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { TOOL_SERVICE } from '../services/tool.service';

var heroStyle = {
   backgroundImage: `url(${hero_bg})`,
   height:'auto',
   backgroundSize: 'cover',
}

export default function Homepage() {
   const dispatch = useDispatch(); 

   useEffect(() => {
      document.body.style.backgroundColor = 'white';
      dispatch(stateAction.updateState({currentFormTitle: "home"})); 
    }, []);

  return (
   <div className='container'>
      {/* 
      
      **** WARNING ***********

      ONLY UNCOMMENT IF GAY OR WILLIGN TO RISK IT ALL 
      
      <button onClick={(e) => { TOOL_SERVICE.deleteEmptyTags(); }}> This button shouldn't be visible </button> 
      
      */}
      <div className='home-header fullwidth' style={heroStyle}>
         <div className='container w-full'>
         <div className='hero-text-container relative'>
            <h1 className='roc-med relative'>Dedicated to making sure your lost items find a way BackHo.me   </h1>
            <img src={hero_icon} alt="hero icon" />
         <Link to="/how-it-works" className='hero-button button-white'>How it works</Link>
         </div>
         </div>
      </div>
      
      <div className='home-welcome'>
         <h1 className='roc-bold'>Welcome</h1>
         <p>BackHo.me supports the recovery of lost and found property by providing an affordable service to report your item as lost and contact the owner of items that are found. </p>
         <div className='home-grid grid'>
            <div className='card relative'>
            <img class="card-icon" src={icon1} alt="question mark icon" />
               <h3>Lost</h3>
               <p>A uniquely waterproof NFC tag that sticks to your item. Manage your items on the dashboard and let us know if it’s lost!</p>
               <Link to="/login" className='hero-button button-white'>Get started</Link>
            </div>
            <div className='card relative'>
            <img class="card-icon" src={icon2} alt="exclamation mark icon" />
               <h3>Found</h3>
               <p>Click to get started and help someone's item find a way Backho.me</p>
               <Link to="/found" className='hero-button button-white'>Get started</Link>
            </div>
            <div className='card relative'>
            <img class="card-icon" src={icon3} alt="shopping icon" />
               <h3>Shop</h3>
               <p>The tags can be preordered on our Kickstarter campaign. Pre-order now and you’ll receive the early pioneer’s discount</p>
               <Link target='_blank' to="https://shop.backho.me/" className='hero-button button-white'>Get started</Link>
            </div>
         </div>
         <div className='banner w-full text-center'>
        <div className='container'>
         <h3>Backho.me Store</h3>
            <p>We’re thrilled to announce that our Backho.me tags are now available directly through our very own store!</p>
            <p>Say goodbye to the worry of losing your valuables; our tags ensure that your items can always find their way back to you.
Why Shop with Us? Be among the first to get our innovative tags for your devices and luggage..</p>
            <p>Enjoy exclusive discounts and special offers directly through us.</p>
            <p><strong>Simple, Secure, No Subscriptions: Our tags are designed for ease of use without any ongoing costs.</strong></p>
            <p>Setting them up is a breeze, and they link directly to our secure web app to help safely return your items. Click the link in the menu or the button below to start shopping now and make losing things a thing of the past!</p>
            <p>Help your items find a way backho.me</p>
            <Link target='_blank' className='button not-found-button' to="https://shop.backho.me/">Get Started</Link>
            </div>
         </div>
      </div>
   </div>
   );
}
