import './App.scss';
import NavBar from './navigation/nav-bar.component';
import { Box } from '@mui/system';
import { Routes, Route, Navigate } from 'react-router-dom';
import Homepage from './components/homepage.component';
import Login from './components/login.component';
import Account from './components/account.component'; 
import Register from './components/register.component';
import HowItWorks from './components/how-it-works.component';
import Dashboard from './components/dashboard.component';
import ActivateTag from './components/activate-tag.component';
import Footer from './navigation/footer.component';
import Found from './components/found'
import FoundReunite from './components/found-reunite';
import FoundThanks from './components/found-thanks';
import ScannedTag from './components/scanned-tag.component';
import HowToScan from './components/how-to-scan.component';
import EditTag from './components/edit-tag.component';
import PasswordReset from './components/password-reset.component';
import NotFound from './components/404.component';
import Faq from './components/faq.component';
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Chat from './components/chat.component';
import Campaign from './components/campaign-page.component';

function LoadingScreen() {
  document.body.style.backgroundColor = '#E9F5F1'
  return (
    <Box>
      <NavBar />
      <div className='loading-container'>
          <div className="loading-spinner"></div>
      </div>
    </Box>
  );
}
function App() {
  const [authed, setAuthed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();

    const logOut = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthed(true);
      } else {
        setAuthed(false);
      }
      setLoading(false);
    });

    return () => {
      logOut();
    };
  }, []);

  // Render loading screen while loading authentication
  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Box>
      <NavBar /> 
      <Box component="main">
        <Routes>
          {/* no auth routes */}
          <Route path="/home" element={<Homepage />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="" element={<Homepage />} />
          <Route path="/how-to-scan" element={<HowToScan />} />
          <Route path="/found" element={<Found />} /> 
          <Route path="/found/reunite" element={<FoundReunite />} />
          <Route path="/found/thank-you" element={<FoundThanks />} />
          <Route path="/register" element={<Register />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/tag" element={<ScannedTag />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/chat" element={<Chat />} /> 
          <Route path="/campaign" element={<Campaign />} />
          {/* authed routes */}
          <Route path="/login" element={authed ? <Navigate to="/dashboard" /> : <Login />} />
          <Route path="/account" element={authed ? <Account /> : <Login />} />
          <Route path="/activeTag" element={authed ? <ActivateTag /> : <Login />} /> 
          <Route path="/activeTag/activated" element={authed ? <Navigate to="/dashboard" /> : <Login />} /> 
          <Route path="/dashboard" element={authed ? <Dashboard /> : <Navigate to="/login" />} />
          <Route path="/editTag" element={authed ? <EditTag /> : <Navigate to="/login" />} />  
           {/* 404 page */}
           <Route path="/*" element={<NotFound />} />
        </Routes>
      </Box>
      <Footer />
    </Box>
  );
}

export default App;
