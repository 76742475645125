import { arrayUnion, runTransaction, 
    collection, getDoc, getDocs, where,
     query, addDoc, setDoc, doc, 
    updateDoc, arrayRemove, deleteDoc
} from "firebase/firestore"; 

import { db } from "../store/firebase.config";

const chatsRef = collection(db, "chat");

export const CHAT_SERVICE = {
    createChatDoc: async function createChatDoc(chatInfo, callback){
        const docRef = await addDoc(chatsRef, {
            userId: chatInfo.id,
            receivedMessages: [],
            sentMessages: [],
            foundUserEmail: chatInfo.email,
            foundUserName: chatInfo.name
        }) 
        const docSnap = await getDoc(docRef)
        .then((response) => callback(response, chatInfo.email))
        .catch((error) => callback(error));
    },
    updateChatDoc: async function updateChatDoc(currDoc, callback){ 
        await updateDoc(doc(db, "chat", currDoc.id), currDoc)
        .then((response) => callback(response, currDoc))
        .catch((error) => callback(error));
    }, 
    updateChatDocTranscation: async function updateChatDocTranscation(currDoc, callback) {
        try {
            await runTransaction(db, async (transaction) => {
                const chatDoc = doc(db, "chat", currDoc.id);
                const docSnapshot = await transaction.get(chatDoc);

                if (!docSnapshot.exists()) {
                    throw "Document does not exist!";
                }

                const newData = {
                    ...docSnapshot.data(),
                    ...currDoc
                };

                if (currDoc.receivedMessages) {
                    newData.receivedMessages = arrayUnion(...currDoc.receivedMessages);
                }

                if (currDoc.sentMessages) {
                    newData.sentMessages = arrayUnion(...currDoc.sentMessages);
                }
                
                    transaction.update(chatDoc, newData);
                });

            callback("Transaction success!");
        } catch (e) {
            console.log("Transaction failed: ", e);
            callback(e);
        }
    }, 
    getChatsByUserId: async function getChatsByUserId(userId, callback){
        const q = query(chatsRef, where("userId", "==", userId)); 
        await getDocs(q)
        .then((response) => callback(response.docs))
        .catch((error) => callback(error));  
    },  
    getChatDocByDocId: async function getChatDocByDocId(docId, callback) {
        const docRef = doc(chatsRef, docId); 
        await getDoc(docRef)
        .then((response) => callback(response.data(), docId))
        .catch((error) => callback(error)); 
    }
}