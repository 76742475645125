import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../store/firebase.config';
import { useDispatch } from 'react-redux';
import stateAction from '../store/actions/state.action';
import userAction from '../store/actions/user.action';
import { USER_SERVICE } from '../services/user.service';
import { AuthContext } from '../auth-provider.component';
import { Link } from 'react-router-dom';
import '../sass/utilities.scss';
import '../sass/components/button.scss';
import '../sass/components/text-input.scss';

export default function Login(props) {
  const { login } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [recaptchaValue, setRecaptchaValue] = React.useState(''); // Store the reCAPTCHA value

  useEffect(() => {
    document.body.style.backgroundColor = '#F7F7F7';
    dispatch(stateAction.updateState({ currentFormTitle: "Login" }));
  }, []);

  const submitForm = async (e, username, password) => {
    e.preventDefault();
    const fbLogin = await signInWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        const currUser = userCredential.user;
        login(currUser);
        updateUserConfig(currUser);
        USER_SERVICE.getUserDocByEmail(currUser.email, onGetUserDoc);
        setErrorMessage("");
      }).catch((error) => {
        setErrorMessage("Login credentials incorrect.");
      })
  }

  const onGetUserDoc = (response) => {
    var docData = response.docs[0].data();
    dispatch(userAction.updateUser({ doc: docData }));
  }

  const onCheckUserDocExists = (response, user) => {
    if (response) {
      if (response.size == 0) {
        USER_SERVICE.createUserDoc(user, onCreateUser);
      } else {
        response.forEach((doc) => {
          var docData = doc.data();
          dispatch(userAction.updateUser({ doc: docData }));
        })
      }
    }
  }

  const onCreateUser = (response) => {
    if (response) {
      var docData = response.data();
      dispatch(userAction.updateUser({ doc: docData }));
    }
  }

  function updateUserConfig(user) {
    var user2 = user;
    dispatch(userAction.updateUser({ authed: true, config: user2 }));
    USER_SERVICE.checkUserDocExistsOnUserId(user2, onCheckUserDocExists);
  }

  function toRegister() {
    navigate("/register");
  }

  return (
    <div className='container' style={{ minHeight: '75vh' }}>
      <h4> Welcome back!</h4>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <button
          className="button"
          style={{ marginRight: '5%' }}
          onClick={(e) => submitForm(e, username, password)}
        >
          Login
        </button>
        <button className="button" onClick={(e) => toRegister()}>
          Sign up
        </button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <form
          style={{ marginTop: '2em' }}
          onSubmit={(e) => submitForm(e, username, password)}
        >
          <div className="input-container">
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder='EMAIL'
              type="email"
              id="email"
            />
          </div>
          <div className="input-container">
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='PASSWORD'
              type="password"
              id="password"
            />
          </div>
          <Link className='link' to={"/password-reset"}>Reset Password </Link>
          <p style={{ color: 'red' }}> {errorMessage} </p>
          <button
            className="button"
            style={{
              marginTop: '11rem',
              width: '100%',
              bottom: '0px',
            }}
            onClick={(e) => submitForm(e, username, password)}
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );

}
