import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../sass/components/header.scss';
import circularBg from '../assets/images/circular-bg.svg';
import logo from '../assets/images/bkhome-logo-green.svg';
import home from '../assets/images/home-icon.svg';
import profileIcon from '../assets/images/profile-icon.svg';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoutButton from "../components/logout.component";

const navItems = [
  { name: 'Home', path: '/home' },
  { name: 'How it works', path: '/how-it-works' },
  { name: 'Found', path: '/found' },
  { name: 'Lost Account', path: '/login' },
  { name: 'Shop', path: 'https://shop.backho.me' },
  { name: 'Activate a tag', path: '/tag' },
  { name: 'Help', path: '/faq' },
];

export default function NavBar(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const currentPageTitle = useSelector((redux) => redux.state.currentFormTitle);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleButtonClick = (event, path) => {
    event.preventDefault(); // Prevents default anchor behavior
    if (path.startsWith('http')) {
      window.location.href = path; // Redirect to external URL
    } else {
      navigate(path); // Navigate within the app
    }
    handleToggleMenu();
    var burgerContainer = document.getElementById('burger');
    burgerContainer.classList.remove('active');
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const getProfileOrHomeLink = () => {
    if (
      location.pathname === '/login' ||
      location.pathname === '/register' ||
      location.pathname === '/how-it-works' ||
      location.pathname === '/dashboard'
    ) {
      return (
        <Link to="/" onClick={handleCloseMenu}>
          <img src={home} alt="" />
        </Link>
      );
    } else if (
      location.pathname === '/activeTag' ||
      location.pathname === '/account' ||
      location.pathname === '/home' ||
      location.pathname === '/'
    ) {
      return (
        <Link to="/dashboard" onClick={handleCloseMenu}>
          <img src={profileIcon} alt="" />
        </Link>
      );
    } else {
      return (
        <Link to="/" onClick={handleCloseMenu}>
          <img src={home} alt="" />
        </Link>
      );
    }
  };

  return (
    <header className="main-header">
      <div className="container nav-container">
        <nav className={`burger-menu`}>
          <label
            id="burger"
            htmlFor="burger-toggle"
            className={`burger-icon ${menuOpen ? 'active' : ''}`}
            onClick={handleToggleMenu}
          >
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </label>
          <ul id="burger-menu" className="menu">
            <img className="menu-bg" src={circularBg} alt="" />
            <div className="menu-container">
            {navItems.map((item, index) => (
              <li key={index}>
                {item.path.startsWith('http') ? (
                  <a href={item.path} className="roc-bold" rel="noopener noreferrer">
                    {item.name}
                  </a>
                ) : (
                  <a
                    className="roc-bold"
                    onClick={(event) => handleButtonClick(event, item.path)}
                  >
                    {item.name}
                  </a>
                )}
              </li>
            ))}
              <li>
                <LogoutButton />
              </li>
            </div>
          </ul>
        </nav>
        <div className="menu-logo">
          {currentPageTitle == 'home' ? (
            <img src={logo} alt="" />
          ) : (
            <h4> {currentPageTitle} </h4>
          )}
        </div>
        <div className="menu-profile">{getProfileOrHomeLink()}</div>
      </div>
    </header>
  );
}
