import React, { useEffect } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import stateAction from "../store/actions/state.action"; 
import checkMark from "../assets/images/check-mark.svg";
import '../sass/utilities.scss';
import '../sass/components/button.scss';
import '../sass/components/text-input.scss';
import '../sass/index.scss';
 
export default function Activated(props) { 
    const userDoc = useSelector((state) => state.user.doc);
    const dispatch = useDispatch(); 
 
    useEffect(() => {
        document.body.style.backgroundColor = '#E9F5F1';
        dispatch(stateAction.updateState({currentFormTitle: "Found"})); 
    }, []);
 
    return (
    <div className='container max-w-700' style={{minHeight: '75vh'}}>
        <div className='min-h-434 card relative card-how text-center mt-0'>
            <div className="">
                <div>
                    <img className="card-img" src={checkMark} alt="check-mark"></img>
                    <h2>Thanks!</h2>
                    <p className="text-center">We’ve let the owner know<br></br> that you’ve found their item.<br></br> They’ll be in touch shortly. </p>
                </div>
            </div>
        </div>
        <div  className="flex">
        <Link to="/register" className='big-button-white'>Protect Your Items</Link>
        </div>
    </div>
    );

}
