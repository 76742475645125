import React from 'react';
import { useLocation } from 'react-router-dom';

const Campaign = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const logo = params.get('logo-url');
  const title = params.get('title');
  const description = params.get('description');
  const banner = params.get('banner');

  var heroStyle = {
    backgroundImage: banner ? `url(${banner})` : 'none',
    height: 'auto',
    backgroundSize: 'cover',
    marginTop: '25px'
  };

  return (
    <>
      {banner && ( 
        <div className='container'>
          <div className='home-header' style={heroStyle}>
            <div className='container w-full'>
              <div className='hero-text-container relative'></div>
            </div>
          </div>
        </div>
      )}
      <div className='campaign-page container text-center'>
        {logo && <img src={logo} alt="Logo" />}
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </>
  );
};

export default Campaign;
