import React, { useEffect, useState } from "react";
import { auth, db } from "../store/firebase.config";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { USER_SERVICE } from "../services/user.service";
import { useDispatch } from "react-redux";
import userAction from "../store/actions/user.action";
import '../sass/utilities.scss'; 
import '../sass/components/button.scss';
import '../sass/components/text-input.scss';
import stateAction from "../store/actions/state.action";

export default function Register(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = React.useState('');
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [dob, setDOB] = React.useState('');
    const [fullName, setFullName] = React.useState('');
    const [county, setCounty] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [mobileNumber, setMobileNumber] = React.useState(null);
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    
    const [recaptchaValue, setRecaptchaValue] = useState(null); // To store the reCAPTCHA value

    useEffect(() => {
        document.body.style.backgroundColor = '#F7F7F7';
        dispatch(stateAction.updateState({currentFormTitle: "Sign Up"})); 
    }, []);

    const validateMobile = (inputNumber) => {
      let isValid = false;
      //uk only number regex
      isValid = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(inputNumber);
      if(inputNumber == '' || inputNumber == undefined || inputNumber == null){
        isValid = false;
      }
      if(!isValid){
        setErrorMessage("Mobile is invalid. UK Number only." )
      }
      return isValid; 
    }
    const validateEmail = () => {
        setEmailErrorMessage("");
        let isValid = false; 
        isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
        if(email == "" || email == undefined || email == null){
            isValid = false;
        }
        if(!isValid){
            setEmailErrorMessage("Email is invalid.")
        }
        return isValid; 
    }  

    const validatePassword = () => {
        setPasswordErrorMessage("");
        let isValid = true; 
        if(password !== '' && confirmPassword !== ''){
            if (password !== confirmPassword) {
                isValid = false; 
            }
        }
        if(!isValid){
            setPasswordErrorMessage("Passwords do not match.");
        }
        return isValid;
    } 
    
    const onCreateUser = (response) => {
         if(response){  
            var docData = response.data();
            dispatch(userAction.updateUser({doc:docData}));  
            toLogin();  
        }
    }

    const addUser = async (user) => {
        user.fullName = fullName; 
        user.email = email;
        user.dob = dob; 
        user.address = address; 
        user.county = county; 
        user.country = country;
        if(mobileNumber) user.mobileNumber = mobileNumber; 
        USER_SERVICE.createUserDoc(user, onCreateUser);
    };

    const submitForm = (e, fullName, email, password, dob, address, county, country) => {
        e.preventDefault();
        if(fullName && email && password && dob && address && county && country && mobileNumber){
            setErrorMessage("");
            if(validatePassword() && validateEmail()) {
                if(mobileNumber && mobileNumber != '' && mobileNumber != null){
                  if(!validateMobile(mobileNumber)){
                    setErrorMessage("Invalid backup mobile number, UK only");
                    return 
                  }  
                }
                createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    sendEmailVerification(auth.currentUser)
                    .then(() => { 
                        addUser(userCredential.user);
                    })
                    .catch(err => setErrorMessage(err.message)); 
                })
                .catch(err => setErrorMessage(err.message)); 
            } else {
                setErrorMessage("Please fill in all fields and complete the reCAPTCHA.");
            }
        }else {
            setErrorMessage("Please fill in all fields");
        }
        setPassword("");
        setConfirmPassword("");
    }

    function toLogin(props){
        navigate("/login");
    }

    function toVerifyEmail(props){
        navigate("/verifyEmail");
    }

    return (
    <div className='container' style={{minHeight: '75vh'}}>
        <h2> Sign up to start logging and protecting your items today!</h2>
        <div style={{display:'flex', justifyContent:'flex-start'}}> 
          <button className="button" onClick={(e) => toLogin()}>  Login </button>
        </div>
        <form style={{marginTop: '2em'}} onSubmit={(e) => submitForm(e, fullName, email, password, dob, address, county, country)}>
          <div className="input-container">
            <input value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="FULL NAME" type="email" id="email" />
          </div>
          <div className="input-container">
            <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="EMAIL" type="email" id="email" />
          </div>
          <div className="input-container">
            <input value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="MOBILE NUMBER" type="number" id="mobileNumber" />
          </div>
          <div className="input-container">
            <input placeholder="PASSWORD" value={password} onChange={(e) => setPassword(e.target.value)} type="password" id="password" />
          </div> 
          <div className="input-container">
            <input placeholder="REPEAT PASSWORD" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type="password" id="password" />
          </div> 
          <div className="input-container dob">
          <label className="dob_label">D.O.B: </label>
            <input value={dob} onChange={(e) => setDOB(e.target.value)} type="date" id="password" />
          </div> 
          <div className="input-container">
            <textarea placeholder="ADDRESS"  style={{textAlign: '-webkit-auto'}} value={address} onChange={(e) => setAddress(e.target.value)} type="textarea" id="password" />
          </div> 
          <div className="input-container">
            <input placeholder="COUNTY" value={county} onChange={(e) => setCounty(e.target.value)} type="text" id="password" />
          </div> 
          <div className="input-container">
            <input placeholder="COUNTRY" value={country} onChange={(e) => setCountry(e.target.value)} type="text" id="password" />
          </div> 
          
          <p style={{color:'red'}}> {errorMessage} </p>
          <p style={{color:'red'}}> {emailErrorMessage} </p>
          <p style={{color:'red'}}> {passwordErrorMessage} </p>

          <button className="button" style={{ marginTop: '2rem', width:'100%' }}
           onClick={(e) => submitForm(e, fullName, email, password, dob, address, county, country)}> Sign Up </button>
        </form>
      </div>
    );

}
