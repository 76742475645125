import React, { useEffect } from "react";
import '../sass/utilities.scss';
import '../sass/components/button.scss';
import '../sass/components/text-input.scss';
import '../sass/index.scss';
import { useSearchParams } from "react-router-dom";
import { TAG_SERVICE } from "../services/tag.service";
import svgIcon from '../assets/images/nfc-icon.svg'
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import stateAction from "../store/actions/state.action";
import { useNavigate } from "react-router-dom";

export default function ScannedTag() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [tagId, setTagId] = React.useState(null);
    const [currentTagDoc, setCurrentTagDoc] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        var tagId = searchParams.get("tagId");
        setTagId(tagId);
        if (tagId != null && tagId.length > 0) {
            TAG_SERVICE.getTagByTag(tagId, onGetTagByTag);
        }
    }, []);

    const onGetTagByTag = (response) => {
        if (response) {
            var doc = response.data();
            setCurrentTagDoc(doc);
        }
    }

     const onGetTagByTagLate = (response) => {
        if(response){
            var doc = response.data();  
            if(doc != null){
                if(doc.status == 'activated'){ 
                    dispatch(stateAction.updateState({reuniteTag: tagId})); 
                    navigate("/found/reunite") 
                } else {
                    dispatch(stateAction.updateState({ unregisteredTag: tagId, routeFromLogin: '/activeTag' }));
                    navigate("/login")
                }
            } else {
                setErrorMessage("Tag not found");
            }
        }
    }

    const submitForm = (e) => {
        e.preventDefault();
        if (currentTagDoc == null && tagId != null) {
            TAG_SERVICE.getTagByTag(tagId, onGetTagByTagLate);
        }

            if (currentTagDoc != null && tagId != 0) {
                if (currentTagDoc.status == 'activated') {
                    dispatch(stateAction.updateState({reuniteTag: tagId})); 
                    navigate("/found/reunite")
                } else {
                    dispatch(stateAction.updateState({ unregisteredTag: tagId, routeFromLogin: '/activeTag' }));
                    navigate("/login")
                }
            } else {
                setErrorMessage("Tag not found");
            }
    }

    return (
        <div className='container'>
            <div>
                <h3 className="text-center">Enter the tag code to continue.</h3>
                <p className="text-center">Please enter the tag code in the box below. If you have already scanned the tag, then it’s code will be automatically populated.</p>
                <form className="white-form no-border pb-20" style={{ marginTop: '2em' }}>
                    <div className="input-container">
                        <input onChange={(e) => setTagId(e.target.value)} placeholder="Input a tag code" value={tagId} type="text" id="tagID" />
                    </div>
                    <p style={{ color: 'red' }}> {errorMessage} </p>
                    <div className="captcha-container">
            </div>
                    <button className="big-button-white"
                        onClick={(e) => submitForm(e)}> Enter </button>
                </form>
            </div>
    
            <div className="text-center pb-20">
                <img src={svgIcon}></img>
            </div>
            <h3 className="text-center">Scan the tag</h3>
            <p className="text-center pb-20">Please scan the tag below to continue. If you are unsure, please see our guide on how to scan an NFC tag. </p>
            
            <div className="flex">
                <Link className="big-button-white" to="/how-to-scan">How To Scan</Link>
            </div>
        </div>
    );
}
