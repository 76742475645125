import React, { useState } from 'react';
import { useNavigate} from 'react-router-dom'; 
import '../sass/components/header.scss';
import { useSelector } from 'react-redux';
import {Link} from "react-router-dom";

 
const navItems = [
 {name:'Home', path:"/home"}, 
 {name:'How it works', path:"/how-it-works"},
 {name:'Found', path:"/home"},
 {name:'lost Account', path:"/login"},
 {name:'Shop', path:"/home"},
 {name:'Active a tag', path:"/register"},
 {name:'Help', path:'/contact'}
];

  
export default function Footer(props) {
  const currentPageTitle = useSelector(redux => redux.state.currentFormTitle);
  const currentYear = new Date().getFullYear();


  return (
    <footer className='main-footer'>
        <div className='container'>
            <p>©{currentYear} Backho.me. All rights reserved.</p>
        </div>
    </footer>
    
  );
}
